<template>
    <div class="container-fluid px-5 d-none d-lg-block" style="background-color: #28a128 !important;">
        <div class="row gx-0 align-items-center" style="height: 45px;">
            <div class="col-lg-8 text-center text-lg-start mb-lg-0">
                <div class="d-flex flex-wrap">
                    <a href="#" class="text-light me-4">Bappenas</a>
                    <a href="#" class="text-light me-4"><i class="fas fa-phone-alt text-danger me-2"></i>021 3193 6207/ 021 3145 374</a>
                    <a href="#" class="text-light me-0"><i
                            class="fas fa-envelope text-danger me-2"></i>indarto@bappenas.go.id</a>
                </div>
            </div>
            <div class="col-lg-4 text-center text-lg-end">
                <div class="d-flex align-items-center justify-content-end">
                    <a href="#" class="btn nav-fill me-6 text-white">#IME SIMURP</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Topbar End -->
    <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0" style="z-index: 1;">
            <h1>
                <img src="../../../../../src/assets/img/gis2.png" height="70">
            </h1>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav ms-auto py-0">
                    <a href="/"
                        :class="[((route.name == 'Beranda') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        BERANDA
                    </a>
                    <a :href="'/Ringkasan/'+bytes"
                        :class="[((route.name == 'Ringkasan') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        RINGKASAN EKSEKUTIF
                    </a>
                    <a href="/DaftarDaerahIrigasi"
                        :class="[((route.name == 'DaftarDaerahIrigasi') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        DAERAH IRIGASI
                    </a>
                    <div class="nav-item dropdown">
                        <a href="#" data-bs-toggle="dropdown"
                            :class="[(((route.name == 'DaftarIP3A') || (route.name == 'DaftarGP3A') || (route.name == 'DaftarP3A')) ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle')]">
                            EVKIN I/G/P3A
                        </a>
                        <div class="dropdown-menu m-0">
                            <a :href="'/DaftarIP3A/'+bytes"
                                :class="[((route.name == 'DaftarIP3A') ? 'dropdown-item active' : 'dropdown-item')]">
                                IP3A
                            </a>
                            <a :href="'/DaftarGP3A/'+bytes"
                                :class="[((route.name == 'DaftarGP3A') ? 'dropdown-item active' : 'dropdown-item')]">
                                GP3A
                            </a>
                            <a :href="'/DaftarP3A/'+bytes"
                                :class="[((route.name == 'DaftarP3A') ? 'dropdown-item active' : 'dropdown-item')]">
                                P3A
                            </a>
                        </div>
                    </div>
                    <a :href="'/DaftarTPM/'+bytes"
                        :class="[((route.name == 'DaftarTPM') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        TPM
                    </a>
                    <div class="nav-item dropdown">
                        <a href="#" data-bs-toggle="dropdown"
                            :class="[(((route.name == 'DaftarUPI') || (route.name == 'DaftarPTGA') || (route.name == 'DaftarOP') || (route.name == 'DaftarKOMIR')) ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle')]">
                            PENGELOLA IRIGASI
                        </a>
                        <div class="dropdown-menu m-0">
                            <a :href="'/DaftarUPI/'+bytes"
                                :class="[((route.name == 'DaftarUPI') ? 'dropdown-item active' : 'dropdown-item')]">
                                UPI
                            </a>
                            <a :href="'/DaftarPTGA/'+bytes"
                                :class="[((route.name == 'DaftarPTGA') ? 'dropdown-item active' : 'dropdown-item')]">
                                PTGA
                            </a>
                            <a :href="'/DaftarOP/'+bytes"
                                :class="[((route.name == 'DaftarOP') ? 'dropdown-item active' : 'dropdown-item')]">
                                PETUGAS OP
                            </a>
                            <a :href="'/DaftarKOMIR/'+bytes"
                                :class="[((route.name == 'DaftarP3A1') ? 'dropdown-item active' : 'dropdown-item')]">
                                KOMIR
                            </a>
                        </div>
                    </div>
                    <a :href="'/GIS/'+bytes"
                        :class="[((route.name == 'GIS') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        PETA
                    </a>
                    <a href="/DaftarRegulasi"
                        :class="[((route.name == 'DaftarRegulasi') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        DOKUMEN
                    </a>
                    <a href="/KontakKami"
                        :class="[((route.name == 'KontakKami') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        KONTAK KAMI
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        swal
    },
    data() {
        return {
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            bytes : encodeURIComponent(CryptoJS.AES.encrypt('Kosong', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
        };
    },
    methods: {
        gantibahasa(bahasanya) {
            localStorage.setItem("bahasasistem", bahasanya);
            location.reload();
        },
    },
    mounted() {

    },
}
</script>

<style></style>